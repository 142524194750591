import Tooltip from '../../../../node_modules/bootstrap/js/src/tooltip'

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]'),
      tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))

function removeTooltipClick () {
  const tooltipLinks = document.querySelectorAll('[data-cc-tooltip]');

  for (let i = 0; i < tooltipLinks.length; i++) {

    tooltipLinks[i].addEventListener('click', (e) => {
      e.preventDefault();
    });
  }
}

removeTooltipClick();
