// Component imports
import { navigationSearchFocusClose } from './navigation'
import {
  pageIndexShown,
  pageIndexHidden,
  pageIndexPositionSetClass,
  pageIndexToPos,
  pageIndexCloseCollapseByAnchor
} from './c-page-index'
import {
  closeSearchFiltersElement,
  openSearchResults,
  closeSearchResults
} from './searchresults'
import './tooltip'
import './popover'
import './c-filter'
import { setWidthCTable } from './c-content'
import { 
  checkPagePosition,
  scrollToTop
} from './scroll-to-top'

// Global variables
const windowWidth = window.innerWidth,
      mql = (mql) => {
  const mediaQL = window.matchMedia(mql)
  return mediaQL.matches;
}

// Init actions
openSearchResults()
closeSearchResults()
scrollToTop()
pageIndexPositionSetClass()
setWidthCTable()

if (mql('(min-width: 992px)') === false && windowWidth < 992) {
  pageIndexPositionSetClass()
  pageIndexHidden()
  pageIndexToPos()
  pageIndexCloseCollapseByAnchor()
} else {
  pageIndexShown()
}

// Actions on resize
window.addEventListener('resize', () => {
  const windowWidth = window.innerWidth

  // Will slide classes and set attribute to false regarding filters element
  if (mql('(min-width: 992px)') === false && windowWidth < 992) {
    closeSearchFiltersElement()
    pageIndexHidden()
  } else {
    pageIndexShown()
  }
  
  navigationSearchFocusClose()
  setWidthCTable()
});

// Actions on scroll
window.addEventListener('scroll', () => {
  checkPagePosition()
  pageIndexPositionSetClass() 
});
