function pageIndexShown () {
  const pageIndexCollapse = document.querySelector('#js-page-index-collapse'),
        button = document.querySelector('#js-page-index-button');

  if (pageIndexCollapse) {
    pageIndexCollapse.classList.add('show');
    button.setAttribute('aria-expanded', 'true');
    button.setAttribute('disabled', true);
    button.removeAttribute('data-bs-toggle');
    
    button.setAttribute('href', '#');
    button.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
    })
  }
}

function pageIndexHidden () {
  const pageIndexCollapse = document.querySelector('#js-page-index-collapse'),
        button = document.querySelector('#js-page-index-button');
  
  if (pageIndexCollapse) {
    pageIndexCollapse.classList.remove('show');
    button.setAttribute('aria-expanded', 'false');
    button.setAttribute('data-bs-toggle', 'collapse');
    button.removeAttribute('disabled');
    button.setAttribute('disabled', false);
  }
}

function pageIndexPositionSetClass () {
  const pageIndex = document.querySelector('#js-page-index');
  if(pageIndex) {
    const rect = pageIndex.getBoundingClientRect();

    if (pageIndex) {
      if (rect.top < 70) {
        pageIndex.classList.add('is-sticky-mobile');
      } else if (rect.top > 71) {
        pageIndex.classList.remove('is-sticky-mobile');
      }
    }
  }
}

function pageIndexToPos () {
  const pageIndex = document.querySelector('#js-page-index'),
        rect = pageIndex.getBoundingClientRect(),
        collapse = document.querySelector('#js-page-index-collapse'),
        button = document.querySelector('#js-page-index-button');
        
  if (pageIndex) {
    collapse.addEventListener('show.bs.collapse', (e) => {
      const YCoord = rect.top;
      
      if (button && !button.hasAttribute('data-bs-toggle')) {
        window.scrollTo({
          top: YCoord,
          behavior: 'smooth'
        });
      }
    });
  }
}

function pageIndexCloseCollapseByAnchor () {
  const pageIndex = document.querySelector('#js-page-index'),
        anchors = document.querySelectorAll('[data-cpi-anchor]');

  if (pageIndex) {
    anchors.forEach((currentElement) => {
      currentElement.addEventListener('click', () => {
        pageIndexHidden();
      });
    });
  }
}

window.onscroll = function() {
  
};

function progressOnScroll() {
  const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  const scrolled = (winScroll / height) * 100;
  document.getElementsByClassName("c-page-index__indicator-bar")[0].style.transform = "translate(" + scrolled + "%)";
}

window.addEventListener('scroll', function() {
  let currentIndex = -1;
  const mainElement = document.querySelector('main');
  const headerElement = document.querySelector('header');
  const content = Array.from(mainElement.querySelectorAll('h1, h2'));
  let indexOffset = 1;
  if(headerElement){
      indexOffset = 1;
  } else { indexOffset = 0; }

  const anchors = Array.from(document.querySelectorAll('.c-page-index__anchor'));
  const offset = 0.25 * window.innerHeight;

  content.forEach((header, index) => {
    if (header) {
      const rect = header.getBoundingClientRect();
      if (rect.top <= window.innerHeight / 2 - offset) {
        currentIndex = index+indexOffset;
      }
    }
  });

  if (currentIndex === -1) {
    currentIndex = 0;
  }

  anchors.forEach((anchor, index) => {
    if (index === currentIndex) {
      anchor.classList.add('is-active');
    } else {
      anchor.classList.remove('is-active');
    }
  });
});




var debounceTimer;
  window.addEventListener('scroll', function() {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(function() {
      CenterLeftMenu();
      }, 100);
  });


  function CenterLeftMenu() {
    var container = document.querySelector('.c-page-index__list-wrap');
    var activeElement = container.querySelector('a.is-active');
  
    if (activeElement) {
        var activeElementOffset = activeElement.offsetTop;
        var activeElementHeight = activeElement.offsetHeight;
        var containerHeight = container.offsetHeight;
  
        var scrollToPosition = activeElementOffset - ((containerHeight - activeElementHeight) / 2);
  
        container.scrollTo({
          top: scrollToPosition,
          behavior: 'smooth'
        });
    } else {
        console.log('No active element found');
    }
  }
  


export {
  pageIndexShown,
  pageIndexHidden,
  pageIndexPositionSetClass,
  pageIndexToPos,
  pageIndexCloseCollapseByAnchor
};
