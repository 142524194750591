function setWidthCTable () {
  const tables = document.querySelectorAll('.c-table'),
        mCol = document.querySelector('.c-content__content'),
        sCol = document.querySelector('aside'),
        mql = (mql) => {
          const mediaQL = window.matchMedia(mql)
          return mediaQL.matches
        }

  if (tables && mCol && sCol) {
    for (let i = 0; i < tables.length; ++i) {
      if (mql('(min-width: 992px') && mql('(max-width: 1279.98px)')) {
        const tableWidth = mCol.clientWidth + 24 + sCol.clientWidth
        tables[i].style.width = tableWidth + 'px'
      } else if (mql('(min-width: 1280px)')) {
        const tableWidth = mCol.clientWidth + 48 + sCol.clientWidth
        tables[i].style.width = tableWidth + 'px'
      }
    }
  }
}

export { setWidthCTable }
