function checkPagePosition() {    
    const button = document.getElementById("scroll-to-top");
    if (button) {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            button.classList.remove('d-none');
        } else {
            button.classList.add('d-none');
        }
    }
}

function scrollToTop() {
    const button = document.getElementById("scroll-to-top");
    if (button) {
        button.addEventListener('click', () => {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        })
    }
}

export {
    checkPagePosition,
    scrollToTop
}