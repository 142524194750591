import Popover from '../../../../node_modules/bootstrap/js/src/popover'

const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]');
const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new Popover(popoverTriggerEl));


document.addEventListener('click', (e) => {
  popoverList.forEach((popover) => {
    popover.hide()
  });
});
