function navigationAddClassCollapse () {
  const menuCollapse = document.querySelector('#navbarSupportedContent');

  menuCollapse.addEventListener('show.bs.collapse', event => {
    menuCollapse.classList.add('fade-in');
  });

  menuCollapse.addEventListener('shown.bs.collapse', event => {
    menuCollapse.classList.remove('fade-in');
    menuCollapse.classList.add('fade-active');
  });
}

function navigationRemoveClassCollapse () {
  const menuCollapse = document.querySelector('#navbarSupportedContent');

  menuCollapse.addEventListener('hide.bs.collapse', event => {
    menuCollapse.classList.remove('fade-active');
    setTimeout(() => {
      menuCollapse.classList.add('fade-out');
    },1);
  });

  menuCollapse.addEventListener('hidden.bs.collapse', event => {
    menuCollapse.classList.remove('fade-out');
  });
}

function navigationSearchOnFocus () {
  const search = document.querySelector('#js-search'),
        searchFocus = document.querySelector('#navbarSupportedContent'),
        searchSuggestions = document.querySelector('#js-search-suggestions');

  search.addEventListener('focus', () => {
    searchSuggestions.classList.remove('d-none');
    setTimeout(() => {
      searchFocus.classList.add('--focus-search');
    },1);
  });
}

function navigationSearchCloseOnMobile () {
  const button = document.querySelector('#js-close-search'),
        searchFocus = document.querySelector('#navbarSupportedContent'),
        searchSuggestions = document.querySelector('#js-search-suggestions');

  if (button) {
    button.addEventListener('click', () => {
      searchFocus.classList.remove('--focus-search');
      setTimeout(() => {
        searchSuggestions.classList.add('d-none');
      },1);
    });
  }
}

function navigationSearchClickOutside () {
  const target = document.querySelector('#js-search-wrap'),
        searchFocus = document.querySelector('#navbarSupportedContent'),
        searchSuggestions = document.querySelector('#js-search-suggestions');

  document.addEventListener('click', (e) => {
    if (!target.contains(e.target)) {
      searchFocus.classList.remove('--focus-search');
      setTimeout(() => {
        searchSuggestions.classList.add('d-none');
      },1);
    }
  })
}

function navigationSearchFocusClose () {
  const searchFocus = document.querySelector('#navbarSupportedContent'),
        searchSuggestions = document.querySelector('#js-search-suggestions');

  if (searchFocus) {
    searchFocus.classList.remove('--focus-search');
    setTimeout(() => {
      searchSuggestions.classList.add('d-none');
    },1);
  }
}

function navigationLanguageMobileToggle () {
  const langDropdown = document.querySelector('.--language'),
        mainMenu = document.querySelector('#js-main-menu');

  langDropdown.addEventListener('show.bs.dropdown', (event) => {
    mainMenu.classList.add('d-none');
    mainMenu.classList.add('d-xl-block');
  });

  langDropdown.addEventListener('hidden.bs.dropdown', (event) => {
    mainMenu.classList.remove('d-none');
    mainMenu.classList.remove('d-xl-block');
  });
}

navigationLanguageMobileToggle();
navigationAddClassCollapse();
navigationRemoveClassCollapse();
navigationSearchOnFocus();
navigationSearchCloseOnMobile();
navigationSearchClickOutside();

export {
  navigationSearchFocusClose
};
