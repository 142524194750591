function openSearchResults () {
  const button = document.querySelector('#js-open-filters'),
        filters = document.querySelector('#js-filters'),
        closeButton = document.querySelector('#js-close-filters');
  
  if (button) {
    button.addEventListener('click', () => {
      filters.classList.add('slide');
      button.setAttribute('aria-expanded', 'true');
      closeButton.setAttribute('aria-expanded', 'true');
    })
  }
}

function closeSearchResults () {
  const button = document.querySelector('#js-close-filters'),
        filters = document.querySelector('#js-filters'),
        openButton = document.querySelector('#js-open-filters');

  if (button) {
    button.addEventListener('click', () => {
      filters.classList.remove('slide');
      button.setAttribute('aria-expanded', 'false');
      openButton.setAttribute('aria-expanded', 'false');
    })
    
  }
}

function closeSearchFiltersElement () {
  const filters = document.querySelector('#js-filters'),
        button = document.querySelector('#js-open-filters'),
        closeButton = document.querySelector('#js-close-filters');
  
  if (button) {
    filters.classList.remove('slide');
    button.setAttribute('aria-expanded', 'false');
    closeButton.setAttribute('aria-expanded', 'false');
  }     
}



export { 
  closeSearchFiltersElement,
  openSearchResults,
  closeSearchResults
}
